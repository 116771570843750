<template>
    <div>
        <el-dialog
            title="用户详情"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="1200px"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => closeUserDialog('UserEditForm')"
        >
            <div>
                <el-form
                    ref="UserEditForm"
                    label-width="100px"
                    size="small"
                >
                    <el-descriptions
                        class="margin-top"
                        :column="1"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                        size="small"
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <div v-if="user_details_data.gender === 1"><img :src="$store.state.baseURL+'icon/ziliaonan.png'" height="80px"></div>
                                <div v-else><img :src="$store.state.baseURL+'icon/ziliaonv.png'" height="80px"></div>
                                <div>用户资料</div>
                            </template>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'width':'270px','text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>用户名</span>
                                    </template>
                                    <span>{{user_details_data.username}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>昵称</span>
                                    </template>
                                    <span>{{user_details_data.name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>性别</span>
                                    </template>
                                    <el-tag size="mini" effect="plain" v-if="user_details_data.gender === 1">男</el-tag>
                                    <el-tag size="mini" effect="plain" type="danger" v-else-if="user_details_data.gender === 2">女</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>状态</span>
                                    </template>
                                    <el-tag v-if="user_details_data.state === 0">审核中</el-tag>
                                    <el-tag v-else-if="user_details_data.state === 1" type="success" size="mini">正常</el-tag>
                                    <el-tag v-else type="danger" size="mini">禁用</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item :span=2 :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>审核记录</span>
                                    </template>
                                    <span>{{user_details_data.examine}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item v-if="edit_auth">
                                    <template slot="label">
                                        <span>权限包</span>
                                    </template>
                                    <el-select
                                        v-model="edit_auth_team_id"
                                        filterable
                                        clearable
                                        placeholder="选择权限包"
                                        class="auth_team_idc"
                                        size="small"
                                    >
                                        <el-option
                                            v-for="item in AllAuthTeam"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item v-else>
                                    <template slot="label">
                                        <span>权限包</span>
                                    </template>
                                    <span>{{user_details_data.auth_team_name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <img :src="$store.state.baseURL+'icon/jian2.png'" height="80px">
                                <div>游戏资料</div>
                            </template>
                            <el-descriptions
                                class="margin-top"
                                :column="4"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'width':'270px','text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>等级</span>
                                    </template>
                                    <span>{{exp ? 'LV '+ exp[0] + ' ( '+ exp[1]+'/'+exp[2] +' )' : ''}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>金币</span>
                                    </template>
                                    <span>{{user_details_data.coin}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>对战次数</span>
                                    </template>
                                    <span>{{user_details_data.duleCount}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>荣誉积分</span>
                                    </template>
                                    <span>{{ user_details_data.score }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="4">
                                    <template slot="label">
                                        <span>物品</span>
                                    </template>
                                    <el-descriptions
                                        direction="vertical"
                                        :column="6"
                                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF','width':'16.6%','height':'100px'}"
                                        :contentStyle="{'text-align': 'center','background-color':'#ECF5FF','height':'50px'}"
                                        border
                                    >
                                        <el-descriptions-item v-for="item in user_details_data.items" :key="item.name">
                                            <template slot="label">
                                                <img :src="item.url" height="50px" alt="" style="border-radius: 10px;">
                                            </template> 
                                            <div style="font-family: HarmonyOS_Sans_Black;font-size: 14px;">
                                                <div style="color:#2828FF;">{{ item.name }}</div>
                                                <div><span>数量：</span><span style="color: #CE0000;">{{ item.num }}</span></div>
                                            </div>
                                        </el-descriptions-item>
                                        <el-descriptions-item v-for="i in res_buchong" :key="i+'bc'">
                                            <template slot="label">
                                                <span></span>
                                            </template>
                                            <div style="width: 100%"></div>
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </el-descriptions-item>
                                <el-descriptions-item :span="4">
                                    <template slot="label">
                                        <span>装扮</span>
                                    </template>
                                    <el-tabs v-model="activeName" type="border-card" :stretch="true">
                                        <el-tab-pane label="静态皮肤" name="skin">
                                            <el-carousel :interval="3000" type="card" height="200px">
                                                <el-carousel-item v-for="item in user_details_data.skins" :key="item.name">
                                                    <img :src="item.url" height="80%" alt="" style="border-radius: 10px;">
                                                    <div>{{ item.name }}</div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </el-tab-pane>
                                        <el-tab-pane label="动态皮肤" name="dynamics">
                                            <el-carousel :interval="3000" type="card" height="200px">
                                                <el-carousel-item v-for="item in user_details_data.dynamicbag" :key="item.name">
                                                    <img :src="item.url" height="80%" alt="" style="border-radius: 10px;">
                                                    <div>{{ item.name }}</div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </el-tab-pane>
                                        <el-tab-pane label="角色框" name="frame">
                                            <el-carousel :interval="3000" type="card" height="200px">
                                                <el-carousel-item v-for="item in user_details_data.framebag" :key="item.name">
                                                    <img :src="item.url" height="80%" alt="" style="border-radius: 10px;">
                                                    <div>{{ item.name }}</div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </el-tab-pane>
                                        <el-tab-pane label="背景墙" name="userback">
                                            <el-carousel :interval="3000" type="card" height="200px">
                                                <el-carousel-item v-for="item in user_details_data.backbag" :key="item.name">
                                                    <img :src="item.url" height="80%" alt="" style="border-radius: 10px;">
                                                    <div>{{ item.name }}</div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </el-tab-pane>
                                        <el-tab-pane label="昵称框" name="nickframe">
                                            <el-carousel :interval="3000" type="card" height="200px">
                                                <el-carousel-item v-for="item in user_details_data.nickframebag" :key="item.name">
                                                    <img :src="item.url" height="80%" alt="" style="border-radius: 10px;">
                                                    <div>{{ item.name }}</div>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </el-tab-pane>
                                    </el-tabs>
                                </el-descriptions-item>
                            </el-descriptions>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-dropdown placement="top" style="margin-right: 10px;">
                        <el-button type="primary" plain>发放道具</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放积分') && (this.user_details_data.state === 1)"
                                    type="primary"
                                    plain
                                    @click="dialogScoreVisible = true"
                                    >发放积分</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放金币') && (this.user_details_data.state === 1)"
                                    type="primary"
                                    plain
                                    @click="dialogCoinVisible = true"
                                    >发放风币</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放奖券') && (this.user_details_data.state === 1)"
                                    type="primary"
                                    plain
                                    @click="dialogKeyVisible = true"
                                    >发放奖券</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放物品') && (this.user_details_data.state === 1)"
                                    type="primary"
                                    plain
                                    @click="dialogItemVisible = true"
                                    >发放物品</el-button
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown placement="top" style="margin-right: 10px;">
                        <el-button type="warning" plain>发放装饰</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放皮肤') && (this.user_details_data.state === 1)"
                                    type="warning"
                                    plain
                                    @click="dialogSkinVisible = true"
                                    >静态皮肤</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放动态皮肤') && (this.user_details_data.state === 1)"
                                    type="warning"
                                    plain
                                    @click="dialogDtSkinVisible = true"
                                    >动态皮肤</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放边框') && (this.user_details_data.state === 1)"
                                    type="warning"
                                    plain
                                    @click="dialogFrameVisible = true"
                                    >发放边框</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放背景墙') && (this.user_details_data.state === 1)"
                                    type="warning"
                                    plain
                                    @click="dialogBackVisible = true"
                                    >发背景墙</el-button
                                >
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <el-button
                                    v-if="$_has('发放昵称框') && (this.user_details_data.state === 1)"
                                    type="warning"
                                    plain
                                    @click="dialogNickVisible = true"
                                    >发昵称框</el-button
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    
                    <el-button
                        v-if="$_has('同意注册') && (this.user_details_data.state === 0)"
                        type="success"
                        plain
                        @click="pass_register()"
                        >同意注册</el-button
                    >
                    <el-button
                        v-if="$_has('删除用户') && (this.user_details_data.state === 0)"
                        type="danger"
                        plain
                        @click="del_user()"
                        >删除用户</el-button
                    >
                    <el-button
                        v-if="$_has('用户授权') && (this.user_details_data.state === 1)"
                        type="success"
                        plain
                        @click="show_edit_auth()"
                        >用户授权</el-button
                    >
                    <el-button
                        v-if="$_has('用户注销') && (this.user_details_data.state === 1)"
                        type="danger"
                        plain
                        @click="log_off()"
                        >用户注销</el-button
                    >
                    <el-button
                        plain
                        @click="closeUserDialog('UserEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submit_edit_auth()"
                        >确 定</el-button
                    >
                    <el-button
                        plain
                        @click="cancel_edit_auth()"
                        >取 消</el-button
                    >
                </div>
            </div>
        </el-dialog>
        <score-dialog-component
            :dialogScoreVisible="dialogScoreVisible"
            :id="user_details_data.id"
            @ExitScoreDialog="dialogScoreVisible = false"
        ></score-dialog-component>
        <coin-dialog-component
            :dialogCoinVisible="dialogCoinVisible"
            :id="user_details_data.id"
            @ExitCoinDialog="dialogCoinVisible = false"
        ></coin-dialog-component>
        <key-dialog-component
            :dialogKeyVisible="dialogKeyVisible"
            :id="user_details_data.id"
            @ExitKeyDialog="dialogKeyVisible = false"
        ></key-dialog-component>
        <item-dialog-component
            :dialogItemVisible="dialogItemVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitItemDialog="dialogItemVisible = false"
        ></item-dialog-component>
        <skin-dialog-component
            :dialogSkinVisible="dialogSkinVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitSkinDialog="dialogSkinVisible = false"
        ></skin-dialog-component>
        <dt-skin-dialog-component
            :dialogDtSkinVisible="dialogDtSkinVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitDtSkinDialog="dialogDtSkinVisible = false"
        ></dt-skin-dialog-component>
        <frame-dialog-component
            :dialogFrameVisible="dialogFrameVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitFrameDialog="dialogFrameVisible = false"
        ></frame-dialog-component>
        <back-dialog-component
            :dialogBackVisible="dialogBackVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitBackDialog="dialogBackVisible = false"
        ></back-dialog-component>
        <nick-dialog-component
            :dialogNickVisible="dialogNickVisible"
            :id="user_details_data.id"
            @refresh="$emit('details_row',user_details_data.id)"
            @ExitNickDialog="dialogNickVisible = false"
        ></nick-dialog-component>
    </div>
</template>

<script>
import { getLevelByExp,initLevelTable } from '@/assets/js/common.js'
import { UserEmpowerRequest,UserPassRegisterRequest,UserLogOffRequest,UserDelRequest } from '@/network/user.js'

import ScoreDialogComponent from '@/components/User/ScoreDialogComponent'
import CoinDialogComponent from '@/components/User/CoinDialogComponent'
import KeyDialogComponent from '@/components/User/KeyDialogComponent'
import ItemDialogComponent from '@/components/User/ItemDialogComponent'
import SkinDialogComponent from '@/components/User/SkinDialogComponent'
import DtSkinDialogComponent from '@/components/User/DtSkinDialogComponent'
import FrameDialogComponent from '@/components/User/FrameDialogComponent'
import BackDialogComponent from '@/components/User/BackDialogComponent'
import NickDialogComponent from '@/components/User/NickDialogComponent'

export default {
    name: 'UserEditComponent',
    data() {
        return {
            show_type: 0,
            edit_auth: false,
            edit_auth_team_id: '',
            dialogScoreVisible: false,
            dialogCoinVisible: false,
            dialogKeyVisible: false,
            dialogItemVisible: false,
            dialogSkinVisible: false,
            dialogDtSkinVisible: false,
            dialogFrameVisible: false,
            dialogBackVisible: false,
            dialogNickVisible: false,
            res_buchong: 0,
            activeName: 'skin'
        }
    },
    computed: {
        exp() {
            if(this.user_details_data.exp) {
                const rank = getLevelByExp(this.user_details_data.exp) *1    //当前等级
                const rankExpTable = initLevelTable()   //所有等级所需经验数组
                const rank_exp = rankExpTable[rank]   //当前等级所需总经验
                const rank_shengyu_exp = this.user_details_data.exp*1 - rank_exp //当前等级剩余经验
                const next_rank_exp = rankExpTable[rank*1+1]  //下一级所需总经验
                const next_rank_xuyao_exp = next_rank_exp - rank_exp    //升级下一级需要的总经验（等级差经验）
                return [rank,rank_shengyu_exp,next_rank_xuyao_exp]
            }

            return ''
        }
    },
    methods: {
        closeUserDialog(formName) {
            this.show_type = 0,
            this.edit_auth = false,
            this.edit_auth_team_id = ''
            this.$emit('exitUserDialog')
        },
        show_edit_auth() {
            this.show_type = 1
            this.edit_auth = true
        },
        cancel_edit_auth() {
            this.show_type = 0,
            this.edit_auth = false,
            this.edit_auth_team_id = this.user_details_data.auth_team_id
        },
        submit_edit_auth() {
            this.login_loading = true
            let data = {
                id: this.user_details_data.id,
                auth_team_id: this.edit_auth_team_id
            }
            UserEmpowerRequest(data)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('授权成功!')
                        this.closeUserDialog('UserEditForm')
                        this.$emit('search')
                        this.$emit('details_row',this.user_details_data.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        pass_register() {
            this.login_loading = true
            UserPassRegisterRequest({id: this.user_details_data.id})
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('已通过，该账号可以用于游戏登录了')
                        this.closeUserDialog('UserEditForm')
                        this.$emit('search')
                        this.$emit('details_row',this.user_details_data.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        log_off() {
            this.$confirm(
                '是否确定注销？（一经注销，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    UserLogOffRequest(this.user_details_data.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('已注销!')
                                this.closeUserDialog('UserEditForm')
                                this.$emit('search')
                                this.$emit('details_row',this.user_details_data.id)
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally()
                })
                .catch((err) => {})
        },
        del_user() {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    UserDelRequest(this.user_details_data.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeUserDialog('UserEditForm'))
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        user_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllAuthTeam: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        ScoreDialogComponent,
        CoinDialogComponent,
        KeyDialogComponent,
        ItemDialogComponent,
        SkinDialogComponent,
        DtSkinDialogComponent,
        FrameDialogComponent,
        BackDialogComponent,
        NickDialogComponent
    },
    watch: {
        user_details_data(newVal) {
            this.edit_auth_team_id = newVal.auth_team_id

            if(newVal.items.length  < 7)
            {
                this.res_buchong = 6 - newVal.items.length;
            }
            else
            {
                const yushu = newVal.items.length % 6 === 0 ? 6 : newVal.items.length % 6
                this.res_buchong = 6 - yushu
            }
        }
    },
}
</script>

<style lang='less' scoped>
.font_family {
    font-size: 50px;
}
.el-dropdown-menu__item {
    padding: 0;
}
.el-dropdown-menu {
    padding: 0;
    margin: 0;
}
</style>