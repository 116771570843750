<template>
    <div>
        <el-dialog
            title="发放奖券"
            :visible.sync="dialogKeyVisible"
            :append-to-body="true"
            width="400px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeKeyDialog()"
        >
            <el-form
                :model="KeyForm"
                :rules="rules"
                ref="KeyForm"
                label-width="100px"
            >
                <el-form-item label="奖券类型" prop="type">
                    <el-radio-group v-model="KeyForm.type">
                        <el-radio-button :label="1">绑定KEY</el-radio-button>
                        <el-radio-button :label="2">通用KEY</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="奖券数量" prop="num">
                    <el-input-number
                        v-model="KeyForm.num"
                        :min="1"
                        :max="99"
                        :step-strictly="true"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="success" plain @click="submit('KeyForm')" >发 放</el-button>
                <el-button plain @click="closeKeyDialog()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserGiveKeyRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            KeyForm: {
                type:1,
                num: 1
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: '请选择奖券类型',
                        trigger: 'blur',
                    },
                ],
                num: [
                    {
                        required: true,
                        message: '请填写奖券数量',
                        trigger: 'blur',
                    }
                ]
            },
        }
    },
    computed:{},
    methods:{
        closeKeyDialog() {
            this.KeyForm = {
                num: 1,
                type:1
            }
            this.$emit('ExitKeyDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.KeyForm.user_id = this.id
                UserGiveKeyRequest(this.KeyForm)
                    .then((s) => {
                        if (s.status === 0) {

                            let html = s.result.type === 1 ? '<h2 style="font-weight: bold;color: #2828FF;">【绑定奖券】</h2>' : '<h2 style="font-weight: bold;color: #B15BFF;">【通用奖券】</h2>'
                            s.result.data.forEach(v => {
                                html += '<div>'+v+'</div>'
                            })
                            if(s.result.type === 1) {
                                html += '</br /><div style="font-weight: bold;color: #0080FF;">温馨提示：绑定奖券KEY会自动发放到用户后台</div><div style="font-weight: bold;color: #0080FF;">可以不用手动发给用户</div>'
                            } else {
                                html += '</br /><div style="font-weight: bold;color: #EA0000;">温馨提示：通用奖券KEY需要记录下来手动发给用户</div><div style="font-weight: bold;color: #EA0000;">系统不做记录</div>'
                            }

                            this.$alert(html, '奖券发放成功', {
                                dangerouslyUseHTMLString: true
                            });
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                this.closeKeyDialog()
            })
        }
    },
    filters:{},
    props:{
        dialogKeyVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>