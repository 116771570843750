<template>
    <div>
        <el-dialog
            title="发放积分"
            :visible.sync="dialogScoreVisible"
            :append-to-body="true"
            width="400px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeScoreDialog()"
        >
            <el-form
                :model="ScoreForm"
                :rules="rules"
                ref="ScoreForm"
                label-width="100px"
            >
                <el-form-item label="积分数量" prop="num">
                    <el-input-number
                        v-model="ScoreForm.num"
                        :min="-99999"
                        :max="99999"
                        :step-strictly="true"
                    ></el-input-number>
                </el-form-item>
                <div style="text-align: center;color: #FF0000;font-weight: bold;">
                    <div>发放记录可在【荣誉积分-积分记录】中查看</div>
                </div>
            </el-form>
            <div slot="footer">
                <el-button type="success" plain @click="submit('ScoreForm')" >发 放</el-button>
                <el-button plain @click="closeScoreDialog()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserGiveScoreRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            ScoreForm: {
                num: 1
            },
            rules: {
                num: [
                    {
                        required: true,
                        message: '请填写积分数量',
                        trigger: 'blur',
                    }
                ]
            },
        }
    },
    computed:{},
    methods:{
        closeScoreDialog() {
            this.ScoreForm = {
                num: 1
            }
            this.$emit('ExitScoreDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.ScoreForm.user_id = this.id
                UserGiveScoreRequest(this.ScoreForm)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('积分发放成功!')
                            this.closeScoreDialog()
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        }
    },
    filters:{},
    props:{
        dialogScoreVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>