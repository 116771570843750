<template>
    <div v-loading.fullscreen.lock="loading">
        <user-search-bar-component
            :AllAuthTeam="AllAuthTeam"
            @search="get_user_index"
        ></user-search-bar-component>
        <common-table-component
            details_auth="用户详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_user"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_user_index"
        ></common-page-component>
        <user-edit-component
            :dialogFormVisible="dialogFormVisible"
            :user_details_data="user_details_data"
            :AllAuthTeam="AllAuthTeam"
            @exitUserDialog="dialogFormVisible = false"
            @search="get_user_index"
            @details_row="details_user"
        ></user-edit-component>
    </div>
</template>

<script>
import { UserIndexRequest,UserDetailsRequest } from '@/network/user.js'
import { AuthTeamListRequest } from '@/network/AuthTeam.js'

import UserSearchBarComponent from '@/components/User/UserSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import UserEditComponent from '@/components/User/UserEditComponent'

export default {
    name: 'UserView',
    data() {
        return {
            dialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                state: '',
                username: '',
                name: '',
                auth_team_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'avatar',
                    label: '头像',
                    width: '100px',
                    html: (row) => {
                        return '<img src="'+row.picture+'" height="50px"></img>'
                    }
                },{
                    prop: 'state',
                    label: '状态',
                    width: '80px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '正常'
                            }
                        } else if(d===2) {
                            return {
                                type: 'danger',
                                filter_data: '已禁用'
                            }
                        }
                    }
                },
                {
                    prop: 'name',
                    label: '昵称',
                    minWidth: '170px'
                },
                {
                    prop: 'gender',
                    label: '性别',
                    minWidth: '70px',
                    html: (row) => {
                        if(row['gender']===1) {
                            return `<div class="cell"><span class="el-tag el-tag el-tag--plain el-tag--small">男</span></div>`
                        } else if(row['gender']===2) {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain el-tag--small">女</span></div>`
                        }
                    }
                },
                {
                    prop: 'auth_team_name',
                    label: '权限组',
                    minWidth: '80px'
                },
                {
                    prop: 'create_time',
                    label: '注册时间',
                    minWidth: '160px'
                },
                {
                    prop: 'examine',
                    label: '审核记录',
                    minWidth: '450px'
                }
            ],
            user_details_data: {},
            AllAuthTeam: []
        }
    },
    computed: {},
    methods: {
        get_user_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.username = param.username ?? this.cond.username
            this.cond.name = param.name ?? this.cond.name
            this.cond.auth_team_id = param.auth_team_id ?? this.cond.auth_team_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            UserIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_user(id) {
            this.dialogFormVisible = true
            this.loading = true
            UserDetailsRequest(id)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.user_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_auth_team_list() {
            AuthTeamListRequest()
                .then((s) => {
                    this.AllAuthTeam = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_user_index()
        this.get_auth_team_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        UserSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        UserEditComponent
    },
    watch: {},
}
</script>

<style lang='less' scoped></style>