<template>
    <div>
        <el-dialog
            title="发放物品"
            :visible.sync="dialogItemVisible"
            :append-to-body="true"
            width="400px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeItemDialog()"
        >
            <el-form
                :model="ItemForm"
                :rules="rules"
                ref="ItemForm"
                label-width="100px"
            >
                <el-form-item label="选择物品" prop="type">
                    <el-select v-model="ItemForm.type" placeholder="请选择物品" class="type_c">
                        <el-option
                        v-for="item in item_arr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发放数量" prop="num">
                    <el-input-number
                        v-model="ItemForm.num"
                        :min="1"
                        :max="99"
                        :step-strictly="true"
                        class="input_number"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="success" plain @click="submit('ItemForm')" >发 放</el-button>
                <el-button plain @click="closeItemDialog()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserGiveItemRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            ItemForm: {
                type:'',
                num: 1
            },
            rules: {
                type: [
                    {
                        required: true,
                        message: '请选择物品',
                        trigger: 'blur',
                    },
                ],
                num: [
                    {
                        required: true,
                        message: '请填写物品数量',
                        trigger: 'blur',
                    }
                ]
            },
            item_arr: [
                {id:1,name:'角色皮肤自选卡'},
                {id:2,name:'常规皮肤自选卡'},
                {id:3,name:'常规边框自选卡'},
                {id:4,name:'改名卡'},
                {id:5,name:'选角色卡'},
                {id:6,name:'常规个人背景自选卡'},
                {id:7,name:'常规动态皮肤自选'},
                {id:8,name:'常规昵称框自选'},
            ]
        }
    },
    computed:{},
    methods:{
        closeItemDialog() {
            this.ItemForm = {
                num: 1,
                type:''
            }
            this.$emit('ExitItemDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.ItemForm.user_id = this.id
                UserGiveItemRequest(this.ItemForm)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('物品发放成功!')
                            this.$emit('refresh')
                            this.closeItemDialog()
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        }
    },
    filters:{},
    props:{
        dialogItemVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
.type_c,.input_number {
    width: 100%;
}
</style>