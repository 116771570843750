<template>
    <div>
        <el-dialog
            title="发放静态皮肤"
            :visible.sync="dialogSkinVisible"
            :append-to-body="true"
            width="400px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeSkinDialog()"
        >
            <el-form
                :model="SkinForm"
                :rules="rules"
                ref="SkinForm"
                label-width="100px"
            >
                <el-form-item label="选择皮肤" prop="skin_id">
                    <el-select v-model="SkinForm.skin_id" placeholder="请选择皮肤" filterable clearable @change="get_img" class="skin_c">
                        <el-option
                            v-for="item in skin_arr"
                            :key="item.id"
                            :label="item.infant_name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div style="text-align: center;"><img :src="img" height="200px" v-if="img" style="border-radius: 10px;"></div>
            <div slot="footer">
                <el-button type="success" plain @click="submit('SkinForm')" >发 放</el-button>
                <el-button plain @click="closeSkinDialog()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserGiveSkinRequest } from '@/network/user.js'
import { ShopSkinListRequest,ShopSkinDetailsRequest } from '@/network/shop/ShopSkin.js'

export default {
    name:'',
    data(){
        return {
            SkinForm: {
                skin_id: ''
            },
            rules: {
                skin_id: [
                    {
                        required: true,
                        message: '请选择皮肤',
                        trigger: 'blur',
                    },
                ]
            },
            skin_arr: [],
            img: ''
        }
    },
    computed:{},
    methods:{
        closeSkinDialog() {
            this.SkinForm = {
                skin_id: ''
            }
            this.img = ''
            this.$emit('ExitSkinDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.SkinForm.user_id = this.id
                UserGiveSkinRequest(this.SkinForm)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('皮肤发放成功!')
                            this.$emit('refresh')
                            this.closeSkinDialog()
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },
        get_all_skin() {
            ShopSkinListRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.skin_arr = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_img(v) {
            if(v) {
                ShopSkinDetailsRequest(v)
                .then((s) => {
                    if (s.status === 0) {
                        this.img = s.result.data.picture[0]['url']
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }

            this.img = ''
        }
    },
    filters:{},
    props:{
        dialogSkinVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){
        this.get_all_skin()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>